import { useCallback, useEffect, useState } from 'react'

import routes from 'Routes'
import {
  scrollToElement,
  saveCookieBarStylesToStorage,
  getCookieBarStyles,
  storage,
  goTo,
  DDPageError
} from 'utils'

const DEFAULT_TITLE =
  'Empréstimo Pessoal e Consignado Online | Simule seu empréstimo agora com o Itaú'
const SCROLL_DELAY = 200

const routesAllowedAccess = [
  '/',
  '/proposta',
  '/proposta/nome',
  '/proposta/email',
  '/proposta/telefone',
  '/todos-cards'
]

const useMain = ({ location }) => {
  const [title, setTitle] = useState(DEFAULT_TITLE)
  const [alertContent, setAlertContent] = useState({})
  const [isAlertActive, setIsAlertActive] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const styles = getCookieBarStyles()

  const currentPath = location?.pathname

  useEffect(() => {
    window.saveCookieBarStylesToStorage = saveCookieBarStylesToStorage
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    const isRouteAllowedAccess = routesAllowedAccess.includes(currentPath)

    if (!isRouteAllowedAccess) {
      handleValidateUserAuth()
    }
  }, [currentPath])

  const handleValidateUserAuth = () => {
    const token = storage.session.getItem('token')

    if (!token || !token.leadId) {
      DDPageError({ payload: { error: 'unauthenticated user' } })
      goTo('home')
    }
  }

  const handleLocation = useCallback(() => {
    const routeAttr = Object.entries(routes).find(
      ([, value]) => value.path === currentPath
    )
    setTitle(routeAttr[1].title)
    return () => sessionStorage.setItem('lastPath', location.href)
  }, [currentPath, location])

  const handleAlert = (event) => {
    if (event?.detail?.active) {
      setIsAlertActive(event?.detail?.active)
      setAlertContent({
        title: event?.detail?.title,
        description: event?.detail?.description,
        type: event?.detail?.type,
        icon: event?.detail?.icon
      })
    }
  }

  useEffect(() => {
    handleLocation()
  }, [handleLocation])

  useEffect(() => {
    window.setTimeout(() => scrollToElement('.layout-wrapper'), SCROLL_DELAY)

    window.addEventListener('alert', handleAlert)
    return () => window.removeEventListener('alert', handleAlert)
  }, [])

  return {
    title,
    alertContent,
    isAlertActive,
    setIsAlertActive,
    styles,
    isLoaded
  }
}

export default useMain
